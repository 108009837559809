var site = site || {};
var generic = generic || {};
(function($) {
  window.site = site || {};
  var skus = [];
  var i = 0;
  site.mpp = {
    categoryData: {},
    categoryFilterMenus: {
      group_1: ['CAT1573', 'CAT1667', 'CAT1675', 'CAT1687', 'CAT4034'],
      group_2: ['CAT1599', 'CAT1607'],
      group_3: ['CAT1598'],
      group_4: ['CAT1597']
    },
    $addLinks: null,
    init: function() {
      if (typeof site.mpp.data == 'undefined') {
        return;
      }

      this.initAddToBag();

      // refresh with L2 cache
      $(document).on('inventory_status_stored', function() {
      // weed out any products that lack 'isOrderable' or 'Coming Soon' skus
        $(site.mpp.data.category.products).each(function(i, product) {
          var isOrderableSku = $(product.skus).filter(function() {
            var undef;
            return ($(document).data(this.SKU_ID) !== undef) &&
                (($(document).data(this.SKU_ID).isOrderable === 1) || ($(document).data(this.SKU_ID).INVENTORY_STATUS === 3));
          })[0];
          if (typeof isOrderableSku == 'undefined') {
          // remove product from view
            $('li.mpp-box[data-productid="' + product.PRODUCT_ID + '"]').addClass('hidden');
          }
          site.mpp.setDefaultSkuSelection(product, $('li[data-productid="' + product.PRODUCT_ID + '"]'));
        });
        // set the right statuses for the defaults
        $('li.mpp-product[data-productid]').each(function() {
          var $li = $(this),
              $ac = $li.find('.add-to-cart'),
              prod_id = $ac.attr('data-productid'),
              sku_id = $ac.attr('data-skubaseid');
          $(document).trigger('sku:select', {PRODUCT_ID: prod_id, SKU_BASE_ID: sku_id});
        });

        site.mpp.setSelectedShadeSwatch();
      });

      // custom header if applicable
      site.mpp.customHeader();

      // Initialize mobile MPP filters
      site.mpp.setVisibleItemCount();
      site.mpp.initFilter();

      // Initialize shades/skus...
      site.mpp.initSelectbox();
      site.mpp.initShadeBoxes();
      site.mpp.initDefaultShade();
      site.mpp.initSkinTypes();
    },

    setDefaultSkuSelection: function(productData, $qs) {
      var undef;
      var count_valid = 0;
      if ((productData.skus !== undef) &&
        (productData.skus[0] !== undef) &&
        (productData.skus[0].SKU_BASE_ID !== undef)) {
        var sku;
        for (i = 0; i < productData.skus.length; i++) {
          var productSkuBaseId = productData.skus[i].SKU_BASE_ID;
          var productSkuId = productData.skus[i].SKU_ID;
          var o_sku = $(document).data(productSkuId);
          if ((o_sku !== undef) && ((o_sku.isOrderable === 1) || (o_sku.INVENTORY_STATUS === 3))) {
            if (sku === undef) {
              sku = o_sku;
              $qs.trigger('sku:select', {
                PRODUCT_ID: productData.PRODUCT_ID,
                SKU_BASE_ID: sku.SKU_BASE_ID
              });
            }
            count_valid++;
          } else {
            $('[data-skubaseid="' + productSkuBaseId + '"], [data-skuid="' + productSkuId + '"]', $qs).not('.button').not('.btn-favorite').remove();
            $('[rel="' + productSkuBaseId + '"]', $qs).parent().remove();
          }
        }
        $('[data-skubaseid], [data-skuid]', $qs).not('.button').not('.btn-favorite').first().trigger('click');
      }
      // don't show "choose option" area if only one option available...
      if (count_valid === 1 && $('.product[data-productid=' + productData.PRODUCT_ID + '] .sku_select.skin_types option').length === 0) {
        $('.sizes, .skin-type', $qs).addClass('hidden');
        $('.not_shaded_sized:not(.js-is-kit-item)', $qs).removeClass('hidden');
        $('.button.select_shade', $qs).parent('.button-wrapper').addClass('hidden');
      }
    },

    // Filters' code taken from the PC MPP filters and modified for the mobile MPP filters
    setVisibleItemCount: function() { //call on init and when filter choices are applied
      $('h1 .item-count').html('(' + $('.product-list li.mpp-product').not('.hidden').length + ')');
    },
    initFilter: function() {
      var concernArr = new Array();
      var val;
      $('ul#mpp-product-grid li.mpp-box[data-productid]').not('.hidden').each(function(j, prodBox) {
        var prodId = $(prodBox).attr('data-productid');
        var product = site.mpp.data.category.products.filter(function(p) {
          return p.PRODUCT_ID === prodId;
        })[0];
        var skinConcernProd = product.SKIN_CONCERN;
        var skinTypeDesc = product.SKINTYPE_DESC;
        if (typeof product == 'undefined') {
          return true;
        }
        // let's add the skin concerns
        if (skinConcernProd != null) {
          for (i = 0; i < skinConcernProd.length; i++) {
            if (skinConcernProd[i] == null) {
              continue;
            }
            val = skinConcernProd[i].toLowerCase().replace(/[^A-Za-z0-9_]/g, '_');
            $(prodBox).addClass(val);
            if (concernArr.indexOf(val) === -1) {
              concernArr.push(val);
            }
          }
        }
        // let's add the skin types
        if (skinTypeDesc != null) {
          for (i = 0; i < skinTypeDesc.split(',').length; i++) {
            var skinType = skinTypeDesc.split(',')[i];
            val = 'type-' + $.trim(skinType);
            $(prodBox).addClass(val);
            if (concernArr.indexOf(val) === -1) {
              concernArr.push(val);
            }
          }
        }
        // let's add the colour families
        for (i = 0; i < product.skus.length; i++) {
          if (product.skus[i].ATTRIBUTE_COLOR_FAMILY !== null) {
            val = product.skus[i].ATTRIBUTE_COLOR_FAMILY.trim().toLowerCase().replace(/[^A-Za-z0-9_]/g, '_');

            // fix color mappings
            switch (val) {
              case 'natural_browns':
                val = 'naturals_browns';
                break;
              case 'violets___blues':
                val = 'violets_blues';
                break;
            }

            $(prodBox).addClass(val);
            if (concernArr.indexOf(val) === -1) {
              concernArr.push(val);
            }
          }
        }
        // let's add the forms/formats
        if (product.FORMULA !== null) {
          val = product.FORMULA.trim().toLowerCase().replace(/[^A-Za-z0-9_]/g, '_');

          // fix formula mappings
          switch (val) {
            case 'cream_compact':
              val = 'cream';
              break;
            case 'powder_compact':
              val = 'powder';
              break;
          }

          $(prodBox).addClass(val);
          if (concernArr.indexOf(val) === -1) {
            concernArr.push(val);
          }
        }
      });

      // now let's activate only applicable filter options
      for (i = 0; i < concernArr.length; i++) {
        $('.mpp-filter-content li.filter-item.' + concernArr[i]).removeClass('inactive');
      }
      // let's bind active filter options
      $('.mpp-filter-content li.filter-item a').unbind();
      $('.mpp-filter-content li.filter-item a').not('li.inactive a').on('click', function(e) {
        e.preventDefault();
        var filteredActiveItems = $(this);
        filteredActiveItems.toggleClass('active');
        filteredActiveItems.parent().toggleClass('active');
        var activeItemCount = $('.mpp-filter-content li.filter-item a.active').length;
        // Add or remove "active" class on "Apply" button
        if (activeItemCount >= 1) {
          filteredActiveItems.parent().siblings().find('h2 .apply a').addClass('active');
        } else {
          filteredActiveItems.parent().siblings().find('h2 .apply a').removeClass('active');
        }
        site.mpp.filterProducts();
      });

      //let's active filter section and filter group
      for (var key in site.mpp.categoryFilterMenus) {
        if ((site.mpp.categoryFilterMenus[key].indexOf(window.CATEGORY_ID) !== -1)
        && ($('.mpp-filter li.filter-item:not(.inactive)').length !== 1)) { // added a check - e.g. 'Serum' is marked as having filters, but none actually apply
          $('.mpp-filter').removeClass('hidden');
          $('.mpp-filter h2').not('.' + key).addClass('hidden');
          $('.mpp-filter-content li.filter-item').not('.' + key).addClass('hidden');
        }
      }
    },
    filterProducts: function() {
      $('li.mpp-product').addClass('hidden');
      var prods = $('li.mpp-product');
      var activeParams = [];
      // we grab applicable classname filters, but create 'or' class selectors since
      // one filter option may need to encompass multiple concerns or benefits
      $('.mpp-filter-content li.filter-item a.active').each(function() {
        activeParams.push($(this).parent().attr('class'));
      });
      for (i = 0; i < activeParams.length; i++) {
        var selector = 'li.mpp-product.na';
        for (var j = 1; j < activeParams[i].split(' ').length; j++) {
          selector += ', li.mpp-product.' + activeParams[i].split(' ')[j];
        }
        // whittle down the matches
        prods = $(prods).filter(selector);
      }
      if (activeParams.length === 0) {
        $('li.mpp-product').removeClass('hidden');
      }
      $(prods).removeClass('hidden');
      site.mpp.initFilter();
      site.mpp.setVisibleItemCount();
    },
    initAddToBag: function() {
      var that = this;

      function addButtonClick(e, args) {
        var $addLink = $(this);
        e.preventDefault();
        if ($addLink.hasClass('js-click-unbound')) {
          return true;
        }
        var buySkus = [];
        args = typeof args === 'undefined' ? {} : args;
        buySkus.push($addLink.attr('data-skubaseid'));
        var addLinkData = $addLink.data('secondarySkuBaseId');

        if (typeof addLinkData !== 'undefined') {
          buySkus.push(addLinkData.toString());
          args.kitSkus = buySkus.join(',');
          args.kitType = buySkus.join('_').toString() + '_KIT';
          args.kitCat = '';
          args.collectionId = $addLink.data('collectionid') || false;
          args.cartId = $addLink.data('cartid') || false;
          args.collectionName = $addLink.data('collectionname') || false;
        }

        args.skuBaseId = buySkus;
        var loadingParams = {
          'target': e.target,
          'matchTarget': ['display', 'height', 'width'],
          'css': {'text-align': 'center', 'vertical-align': 'top'}
        };
        if (args && args.fromStickyNav) {
          loadingParams.target = args.button;
          loadingParams.matchTarget.push('float', 'margin');
        }
        var loadingId = site.loading.show(loadingParams);
        site.addToCart(args);

      $(document).one('addToCartKitItem.success editToCartKitItem.success addToCart.success addToCart.failure', function() {
          site.loading.hide(loadingId);
        });
      }

      $(site.mpp.data.categories).each(function(i, category) {
        $(category.products).each(function(j, product) {
          var $product = $('li[data-productid=' + product.PRODUCT_ID + ']');

          that.initAddToFavorites($('.btn-favorite', $product), $product, product.skus[0].SKU_BASE_ID);

          var $addLink = $('.add-to-cart', $product);
          $addLink
            .once()
            .attr('data-skubaseid', product.skus[0].SKU_BASE_ID)
            .attr('data-productid', product.PRODUCT_ID)
            .on('click', addButtonClick);
        });
      });
      //Center function for email waitlist Popup
      $.fn.center = function() {
        var windowHeight = $(window).height(),
            windowWidth = $(window).width();
        var topPosition = 0;
        var swap;
        if (windowHeight > 240) {
          topPosition = (windowHeight - 240) / 2;
        }
        // Android 2.3.X Fix
        if (window.orientation === 90 || window.orientation === -90) {
          if (windowHeight > windowWidth) {
            swap = windowHeight;
            windowHeight = windowWidth;
            windowWidth = swap;
          }
        } else {
          if (windowHeight < windowWidth) {
            swap = windowHeight;
            windowHeight = windowWidth;
            windowWidth = swap;
          }
        }
        this.css({
          'left': Math.max(0, ((windowWidth - this.width()) / 2) + $(window).scrollLeft()) + 'px',
          'top': Math.max(0, $(window).scrollTop() + topPosition) + 'px'
        });
        return this;
      };
      $(document).on('sku:select', function(e, skuData) {
        e.preventDefault();
        $('.add-to-cart[data-productid=' + skuData.PRODUCT_ID + ']')
          .attr('data-skubaseid', skuData.SKU_BASE_ID);
        // default to hiding buy button unless L2 data says otherwise
        skuData.isOrderable = 0;
        // update sku inventory status only if found in L2 response (could be absent!)
        var sku_id = 'SKU' + skuData.SKU_BASE_ID;
        if (typeof $(document).data(sku_id) != 'undefined') {
          skuData.INVENTORY_STATUS = $(document).data(sku_id).INVENTORY_STATUS;
          skuData.isOrderable = $(document).data(sku_id).isOrderable;
        }
        var $prod_li = $('li[data-productid="' + skuData.PRODUCT_ID + '"]');
        $('.spp_product_status > div', $prod_li).addClass('hidden');
        $('#order_arrival_info').show();

        if (site.productData.isComingSoon(skuData) || site.productData.isTempOutOfStock(skuData)) {
          var bis_request_type = '';
          if (site.productData.isTempOutOfStock(skuData)) {
            bis_request_type = 'BIS';
            $('.temp_out_of_stock_msg', $prod_li).removeClass('hidden');
          } else if (site.productData.isComingSoon(skuData)) {
            bis_request_type = 'CS';
            $('.coming_soon_stock_msg', $prod_li).removeClass('hidden');
          }
          $('.temp_out_of_stock_msg a', $prod_li).on('click', function(e, link) {
            e.preventDefault();
            $(link).unbind();
            var file = '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuData.SKU_BASE_ID + '&REQUEST_TYPE=' + bis_request_type;
            var options = {
              includeBackground: false,
              includeCloseLink: true,
              hideOnClickOutside: true,
              cssStyle: {border: '1px solid #5dba98', top: '190px', left: '10%', width: 'auto', height: 'auto', padding: '0', background: '#F5F5F5'}
            };
            options.content = '<iframe class="email_waitlist_popup" style="padding:45px 15px 0px;" width="270px" scrolling="no" height="240px" frameborder="0" src="' + file + '"></iframe>';
            options.cssClass = 'email_waitlist_overlay_container';
            generic.overlay.launch(options);
            initEmailWaitListOverlayContainer();

            //set Center position for Email Popup
            $(window).on('scroll', function() {
              initEmailWaitListOverlayContainer();
            });
            $(window).on('orientationchange', function() {
              initEmailWaitListOverlayContainer();
            });
            function initEmailWaitListOverlayContainer() {
              var emailWaitListOverlayContainer = $('.email_waitlist_overlay_container');
              emailWaitListOverlayContainer.center();
            }
          });
        } else if (site.productData.isInactive(skuData)) {
          $('.inactive_stock_msg', $prod_li).removeClass('hidden');
        } else if (site.productData.isSoldOut(skuData)) {
          $('#order_arrival_info').hide();
          $('.sold_out_stock_msg', $prod_li).removeClass('hidden');
        }
        // hide or show buy button (isOrderable or coming-soon or out of stock)
        var $addLink = $('a[data-skubaseid="' + skuData.SKU_BASE_ID + '"]').parent();
        if (((skuData.isOrderable === 1) || (skuData.INVENTORY_STATUS === 3)) && (skuData.INVENTORY_STATUS !== 2)) {
          $addLink.show();
        } else {
          if ($addLink.attr('class') === 'mpp_product') {
            $addLink.parent().find('.button-wrapper').hide();
          } else {
            $addLink.hide();
          }
        }
        // set the favorites sku
        site.mpp.initAddToFavorites($('.btn-favorite', $prod_li), $prod_li, skuData.SKU_BASE_ID);

        // set icon if applicable
        site.mpp.setIcons();

        // setup replenishment message
        site.mpp.setReplenishmentMessage(skuData, $prod_li);
      });
    },

    initAddToFavorites: function($addLink, $qs, skuBaseId) {
      $qs = $qs || [];
      $addLink.unbind();
      $addLink.attr('data-skubaseid', skuBaseId);
      $addLink.on('click', function(e) {
        e.preventDefault();
        site.favorites.manipulateWishList({
          skuBaseId: $(this).attr('data-skubaseid'),
          successCallback: function(r) {
            if ($qs) {
              var $message = $('.icon-heart-message', $qs);
              var msg;
              if ($message.length) {
              // Not needed for mobile. For mobile we're kipping it hidden and showing the messages on an overlay
              //$message.addClass('active');
                if (r.SUCCESS === 1 && r.KEY !== 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                  // Get message for signed out users
                  msg = $('.icon-heart-message.favorites-add-success', $qs).find('.favorites-added-signedOut').html();
                } else if (r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                  // Get message for signed in users
                  msg = $('.icon-heart-message.favorites-add-success', $qs).find('.favorites-added-signedIn').html();
                } else if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.removeClass('favorites-add-success');
                  $message.addClass('favorites-add-exists');
                  // Get favorite already exists message
                  msg = $('.icon-heart-message.favorites-add-exists', $qs).find('.favorites-exists').html();
                }
                // Open added to favorites success message in an overlay
                generic.overlay.launch({
                  content: msg,
                  includeBackground: true,
                  hideOnClickOutside: true,
                  includeCloseLink: true
                });
              }
            }
          }
        });
      });
    },
    refreshSkuStatus: function(l2_prod_arr) {
      $('li[data-productid]').each(function(i, prod_div) {
        var prod_id = $(prod_div).attr('data-productid');
        var prod_display = false;
        // does the product have any displayable skus?
        $(l2_prod_arr).each(function(index, prod) {
          if (prod.PRODUCT_ID === prod_id) {
            $(prod.skus).each(function() {
              if ((this.isOrderable === 1) || (this.INVENTORY_STATUS === 3)) {
                prod_display = true;
              }
            });
          }
        });
        if (!prod_display) {
          $(prod_div).remove();
        }
      });
      // trigger sku:select change for sku defaults
      $('.unit > select').change();
    },
    initSelectbox: function() {
    // custom shade dropdown setup
      $('.sku_select.selectbox').selectbox({
        onOpen: function(inst) {
          $(this).parent().find('ul.sbOptions').css('overflow', 'auto');
        },
        onClose: function(inst) {
        //console.log("close", inst);
        },
        onChange: function(val, inst) {
          var prod_id = $(inst.input[0]).parents('li[data-productid]').attr('data-productid');
          $(document).trigger('sku:select', {PRODUCT_ID: prod_id, SKU_BASE_ID: val});
          site.mpp.setSelectedShadeSwatch();
        },
        effect: 'slide'
      });
    },
    initSkinTypes: function() {
      $(site.mpp.data.category.products).each(function(i, product) {
        var undef;
        var skin_types = $('.product[data-productid=' + product.PRODUCT_ID + '] .sku_select.skin_types');
        var skin_types_options = skin_types.find('option');
        if (skin_types_options.length === 0 || skin_types_options.length === 1 && skin_types_options.html() === '') {
          skin_types.selectbox('detach');
          skin_types.remove();
        }

        if (product.skus[0].STRENGTH !== null) {
          if (skin_types.length > 0) {
            skin_types.removeClass('hidden');
          } else {
            for (i = 0; i < product.skus.length; i++) {
              var prodSkuBaseId = product.skus[i].SKU_BASE_ID;
              // update the custom drop-down
              $('a[rel="' + prodSkuBaseId + '"]').html(product.skus[i].STRENGTH);
              // set selected value
              if ($('.button.add-to-cart[data-productid="' + product.PRODUCT_ID + '"][data-skubaseid]').attr('data-skubaseid') === String(prodSkuBaseId)) {
                $('li[data-productid="' + product.PRODUCT_ID + '"] a.sbSelector').html(product.skus[i].STRENGTH);
                $('li[data-productid="' + product.PRODUCT_ID + '"] .sbHolder.sized').removeClass('sized').addClass('skin_types');
              }
            }
          }
        } else if ((product.skus !== undef) &&
          (product.skus[0] !== undef) &&
          (product.skus[0].SKIN_TYPE_TEXT !== undef) &&
          (product.skus[0].SKIN_TYPE_TEXT !== null)) {
          var skinTypes = '';
          var hasDups = false;
          $(product.skus).each(function(j, sku) {
            var expandedSkinTypes = '';
            for (i = 2; i < 6; i++) {
              if (sku.SKIN_TYPE_TEXT.charAt(i) === 1) {
                expandedSkinTypes += i === 2 ? ', ' + (i - 1) :
                  i === 3 ? ', ' + (i - 1) :
                    i === 4 ? ', ' + (i - 1)
                      : ', ' + (i - 1);
              }
            }
            if (skinTypes.indexOf(expandedSkinTypes) !== -1) {
              hasDups = true;
            }
            skinTypes += expandedSkinTypes;
          });
          $(product.skus).each(function(j, sku) {
            var expandedSkinTypes = '';
            var expandedSkinTypeLabel = '';
            for (i = 2; i < 6; i++) {
              if (sku.SKIN_TYPE_TEXT.charAt(i) === 1) {
              // switching from localized text to just numbers per Lauren Castellanos request 6.16
                expandedSkinTypes += i === 2 ? ', ' + (i - 1) :
                  i === 3 ? ', ' + (i - 1) :
                    i === 4 ? ', ' + (i - 1)
                      : ', ' + (i - 1);
                expandedSkinTypeLabel += i === 2 ? ', ' + $('[data-localtrans="very_dry_dry"]:first').html() :
                  i === 3 ? ', ' + $('[data-localtrans="dry_combination"]:first').html() :
                    i === 4 ? ', ' + $('[data-localtrans="combination_oily"]:first').html()
                      : ', ' + $('[data-localtrans="oily"]:first').html();
              }
            }
            // update the custom drop-down
            expandedSkinTypes = expandedSkinTypes.length > 2 ? expandedSkinTypes.substring(2) : expandedSkinTypes;
            expandedSkinTypeLabel = expandedSkinTypeLabel.length > 2 ? expandedSkinTypeLabel.substring(2) : expandedSkinTypeLabel;
            // only apply if we have skin type values, and it isn't a shaded product
            if (!hasDups && (expandedSkinTypes !== '') && ($('li.mpp-product[data-productid="' + product.PRODUCT_ID + '"] .product-addl-info .swatches').length === 0)) {
              $('a[rel="' + sku.SKU_BASE_ID + '"]').html(expandedSkinTypes + ' ' + expandedSkinTypeLabel);
              // flag product as skin-type
              $('li.mpp-product[data-productid="' + product.PRODUCT_ID + '"]').addClass('select_type_formula');
              // set selected value
              if ($('.button.add-to-cart[data-productid="' + product.PRODUCT_ID + '"][data-skubaseid]').attr('data-skubaseid') === String(sku.SKU_BASE_ID)) {
                $('.sbOptions a[rel="' + sku.SKU_BASE_ID + '"]').trigger('click');
              }
            }
          });
        }
      });
    },
    initShadeBoxes: function() {
      $('.shaded ul.sbOptions li').each(function() {
        var alink = $(this).find('a').eq(0);
        var shade = $('option[value="' + alink.attr('rel') + '"]').attr('data-skushade');
        var undef;
        if (shade !== undef) {
          if (shade.split(',').length === 1) {
            $(this).css('background-color', shade);
          } else if (shade.split(',').length > 1) {
            var shade_icon = $('<div class="shade_icon" />');
            for (i = 0; i < shade.split(',').length; i++) {
              shade_icon.append('<div class="multi-' + shade.split(',').length + '" style="background-color: ' + shade.split(',')[i] + ';"></div>');
              // use this to properly display the shade boxes
              $('.swatches div[data-skushade="' + shade + '"]')
                .append('<div class="multi-' + shade.split(',').length + '" style="background-color: ' + shade.split(',')[i] + ';"></div>')
                .css('background-color', 'none');
            }
            $(this)
              .prepend(shade_icon)
              .addClass('multi-shade');
          }
        }
      });
    },
    setSelectedShadeSwatch: function() {
      $('a.sbFocus').each(function() {
        var alink = $(this);
        var shade = $('option[value="' + alink.attr('rel') + '"]').attr('data-skushade');
        var prod_id = $('option[value="' + alink.attr('rel') + '"]').parent().attr('data-productid');
        var $qs = $('li.product[data-productid="' + prod_id + '"]');
        var undef;
        if (shade !== undef) {
          for (i = 0; i < shade.split(',').length; i++) {
            $('.smoosh-panel-' + i + ' > img', $qs).css('background-color', shade.split(',')[i]);
          }
          // set active border
          $('.swatch.active', $qs).removeClass('active');
          $('.swatch[data-skubaseid="' + alink.attr('rel') + '"]', $qs).addClass('active');
          // set icon area of dropdown
          $('.shade_icon:not(.sbOptions .shade_icon)', $qs).remove();
          var shade_icon_html = $('.swatch.active', $qs).html();
          $('.sbHolder', $qs).prepend($('<div class="shade_icon" />').html(shade_icon_html));
          if (shade.split(',').length === 1) {
            $('.shade_icon', $qs).css('background-color', shade);
          }
        }
      });

      var smooshImage = $('.swatch.active').attr('data-skusmoosh');
      site.mpp.changeSmooshImage(smooshImage);
    },
    changeSmooshImage: function(smooshImage) {
      $('.shaded').find('.single-smoosh img').eq(0).attr('src', '/media/export' + smooshImage);
    },
    //set defaults
    initDefaultShade: function() {
      site.mpp.setSelectedShadeSwatch();
      $('.swatches li .swatch')
        .on('mouseover', function() {
          $(this).parents('.product-addl-info').find('.smoosh img').css('background-color', $(this).attr('data-skushade'));
          site.mpp.changeSmooshImage($(this).attr('data-skusmoosh'));
        })
        .on('click touchstart', function() {
          var touchStart = $(this);
          touchStart.parents('.shaded').find('a[rel="' + touchStart.attr('data-skubaseid') + '"]').eq(0).trigger('click');
          touchStart.parents('.shaded').find('.sbOptions a.sbFocus').removeClass('sbFocus');
          touchStart.parents('.shaded').find('.sbOptions a[rel="' + touchStart.attr('data-skubaseid') + '"]').addClass('sbFocus');
          site.mpp.changeSmooshImage(touchStart.attr('data-skusmoosh'));
          site.mpp.setSelectedShadeSwatch();
        });

      $('ul.swatches').on('mouseout', function() {
      // the selectbox JS removes the 'sbFocus' class from the element on mouseout so if you select a shade from the drop down
      // then hover over another shades the mouseout event doesnt return the user to the correct shade so we need to manually set it here
        var $data = $('.swatches li .active');
        var $shade = $data.attr('data-skushade');
        $(this).parents('.shaded').find('.single-smoosh img').css('background-color', $shade);

        site.mpp.changeSmooshImage($data.attr('data-skusmoosh'));
        site.mpp.setSelectedShadeSwatch();
      });

      // set sized icons
      site.mpp.setIcons();

      // shade selection
      $('a.select_shade, a.js-select_kit_component').once('view_options').on('click', function(e) {
        e.preventDefault();
        var button = $(e.target);
        var shadePanel = $(button).parents('li.product').eq(0).find('.shaded');
        if (shadePanel.is(':visible')) {
          shadePanel.slideUp(500);
          button.removeClass('active');
        } else {
          shadePanel.slideDown(500, function() {
            $(this).css('overflow', 'visible');
          });
          //shadePanel.attr('style', 'overflow:visible;');
          button.addClass('active');
          if (button.hasClass('js-select_kit_component')) {
            button.removeClass('js-select_kit_component').addClass('js-select_kit_component_loaded');
            $(document).trigger('sells_with_init', shadePanel);
          } else if (button.hasClass('js-select_kit_component_loaded')) {
            $(document).trigger('sells_with_reinit', shadePanel);
          }
        }
      });

      // set smooshes
      $('.product option[data-skusmoosh]').each(function() {
        var skuSmoosh = $(this);
        var img_ref = $(skuSmoosh).attr('data-skusmoosh');
        var img_arr = img_ref.split(',');
        if (img_arr.length === 1) {
          skuSmoosh.parents('.shaded').find('.single-smoosh img').eq(0).attr('src', '/media/export' + skuSmoosh.attr('data-skusmoosh'));
        } else if (img_arr.length === 2) {
          skuSmoosh.parents('.shaded').find('.single-smoosh, .trio-smoosh, .quad-smoosh').addClass('hidden');
          skuSmoosh.parents('.shaded').find('.duo-smoosh').removeClass('hidden');
          skuSmoosh.parents('.shaded').find('.duo-smoosh img').eq(0).attr('src', '/media/export' + img_arr[0]);
          skuSmoosh.parents('.shaded').find('.duo-smoosh img').eq(1).attr('src', '/media/export' + img_arr[1]);
        } else if (img_arr.length === 3) {
          skuSmoosh.parents('.shaded').find('.single-smoosh, .duo-smoosh, .quad-smoosh').addClass('hidden');
          skuSmoosh.parents('.shaded').find('.trio-smoosh').removeClass('hidden');
          skuSmoosh.parents('.shaded').find('.trio-smoosh img').eq(0).attr('src', '/media/export' + img_arr[0]);
          skuSmoosh.parents('.shaded').find('.trio-smoosh img').eq(1).attr('src', '/media/export' + img_arr[1]);
          skuSmoosh.parents('.shaded').find('.trio-smoosh img').eq(2).attr('src', '/media/export' + img_arr[2]);
        } else if (img_arr.length === 4) {
          skuSmoosh.parents('.shaded').find('.single-smoosh, .duo-smoosh, .trio-smoosh').addClass('hidden');
          skuSmoosh.parents('.shaded').find('.quad-smoosh').removeClass('hidden');
          skuSmoosh.parents('.shaded').find('.quad-smoosh img').eq(0).attr('src', '/media/export' + img_arr[0]);
          skuSmoosh.parents('.shaded').find('.quad-smoosh img').eq(1).attr('src', '/media/export' + img_arr[1]);
          skuSmoosh.parents('.shaded').find('.quad-smoosh img').eq(2).attr('src', '/media/export' + img_arr[2]);
          skuSmoosh.parents('.shaded').find('.quad-smoosh img').eq(3).attr('src', '/media/export' + img_arr[3]);
        }
      });
    },
    setIcons: function() {
    // set sized icons
      $('.sku_select.sized').each(function(j, select) {
        $(select).siblings('.sbHolder').addClass('sized');

        //Disabled dropdown for Single sized Product
        if (select.options.length === 1) {
          $(select).siblings('.sbHolder').addClass('single-size');
        }
        $('option[data-icon!=""]', $(this)).each(function(i, option) {
          var icon_type = $(option).attr('data-icon');
          var sku_b_id = $(option).attr('value');
          $('a[rel="' + sku_b_id + '"]').parent().addClass(icon_type);
          if ($('.sbSelector', $(select).siblings('.sbHolder')).html() === $(option).html()) {
            $(select).siblings('.sbHolder').removeClass('DDML').removeClass('Jar').removeClass('Square').removeClass('Tube').removeClass('Tall').removeClass('Fragrance')
              .addClass(icon_type);
          }
        });
      });
    },
    setReplenishmentMessage: function(skuData, $qs) {
      var sku, undef;
      $(site.mpp.data.category.products).each(function(i, prod) {
        var asku = $(prod.skus).filter(function(j, si) {
          return (skuData.SKU_BASE_ID + '') === (si.SKU_BASE_ID + '');
        })[0];
        if (asku !== undef) {
          sku = asku;
        }
      });
      if ((typeof sku !== 'undefined') && (sku.REPLENISHMENT_MSG !== '') && (sku.REPLENISHMENT_MSG !== null)) {
        $('.replenishment', $qs).removeClass('hidden');
        $('.prod_replen_msg', $qs).addClass('hidden');
        $('.sku_replen_msg', $qs)
          .html(sku.REPLENISHMENT_MSG)
          .removeClass('hidden');
      } else if ($('.prod_replen_msg', $qs).html() !== '') {
        $('.replenishment', $qs).removeClass('hidden');
        $('.prod_replen_msg', $qs).removeClass('hidden');
        $('.sku_replen_msg', $qs).addClass('hidden');
      }
      // setup overlay once
      $('.replenishment', $qs).once().on('click', function(e) {
        e.preventDefault();
        generic.overlay.launch({
          content: $('.replenishment_overlay', $qs).html(),
          includeBackground: true,
          hideOnClickOutside: true,
          includeCloseLink: true
        });
      });
    },
    customHeader: function() {
      if ($('.custom_mpp_header').length > 0) {
        $('.mpp_cat_header').html($('.custom_mpp_header').html());
      }
    }

  };// end site.mpp
})(jQuery);
